<template>
    <div class="message">
        <div class="message-content" v-loading="loading" v-if="isPC">
            <div 
                class="message-content__item"
                v-for="(item, index) in liveData"
                :key="index"
                @click="edit(item.id)"
            >
                <img :style="'width:'+isPC?'200px':'150px'" :src="item.picUrl" />
                <div class="name">直播名称：{{ item.userName }}</div>
                <div class="startTime">直播时间：{{ item.startTime }}</div>
                <div class="live-status">{{ formatStatus[item.liveStatus] }}</div>
            </div>
            <br />
        </div>
        <div class="message-content-mb" v-loading="loading" v-else>
            <div 
                class="message-content__item"
                v-for="(item, index) in liveData"
                :key="index"
                @click="edit(item.id)"
            >
                <img :src="item.picUrl" />
                <div class="name">直播名称：{{ item.title }}</div>
                <div class="startTime">直播时间：{{ item.startTime }}</div>
                <div class="live-status">{{ formatStatus[item.liveStatus] }}</div>
            </div>
            <br />
            
        </div>
        <div class="message-content__bottom" v-if="isPC">
                <el-pagination
                    layout="total, sizes, prev, pager, next, jumper"
                    :page-sizes="[10, 15, 20]"
                    :page-size="pageSize"
                    @size-change="handleSizeChange"
                    @current-change="currentChange"
                    :total="total"
                    background
                ></el-pagination>
        </div>
        <div class="message-content__bottom_mb" v-else>
                <el-pagination
                    layout="total, sizes, prev, pager, next, jumper"
                    :page-sizes="[10, 15, 20]"
                    :page-size="pageSize"
                    @size-change="handleSizeChange"
                    @current-change="currentChange"
                    :total="total"
                    background
                ></el-pagination>
            </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            total: 0,
            currentPage: 1,
            pageSize: 10,
            liveData: [],
            formatStatus: {
                '0': '未开始',
                '1': '进行中',
                '2': '已结束'
            },
            loading:true,
            isPC:true, 
        }
    },
    mounted() {
        this.getLiveData()
        let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
        // alert(flag)
        if(flag){
            this.isPC = false;
            // alert("移动端")
        }
    },
    methods: {
        //获取直播信息
        getLiveData() {
            this.loading = true;
            this.$api.getLiveInfo({ rows: this.pageSize, page: this.currentPage }).then(res => {
                this.liveData = res.data.data
                this.total = res.data.total
                this.loading = false
            })
            
        },
        edit(id) {
            this.$router.push({
                path:'/messageDetail',
                query:{
                    id:id
                }
            })
        },
        //选择每页数量
        handleSizeChange(val) {
            this.pageSize = val
            this.getLiveData()
        },
        //选择页码
        currentChange(currentPage) {
            this.currentPage = currentPage;
            this.getLiveData()
        },
    }
}
</script>

<style scoped lang="less">
.message {
  padding: 20px;
}
.message-content-mb {
    width: 100%;
  margin: 0 auto;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 4px;
  padding: 20px;
  display: flex;
  justify-content: flex-start;
  flex-flow: row wrap;
  text-align: center;
    }
 .message-content {
  width: 80%;
  margin: 0 auto;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 4px;
  padding: 20px;
  display: flex;
  justify-content: flex-start;
  flex-flow: row wrap;
  text-align: center;
}
.message .message-content__item {
  flex: 0 0 18%;
  margin: 10px;
  height: 200px;
  text-align: left;
  position: relative;
  cursor: pointer;
}
.message .message-content__item img {
//   width: 200px;
  height: 100px;
  border-radius: 4px;
}
.message .message-content__item .live-status {
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: #006cff;
  border-radius: 4px;
  width: 60px;
  height: 25px;
  text-align: center;
  color: #fff;
}
.message .message-content__item .name {
  margin: 5px 0px;
  font-weight: 900;
  color: #000;
}
.message .message-content__item .startTime {
  font-size: 12px;
  color: #9fa0a1;
  margin: 5px 0px;
}
.message-content__bottom {
    width: 80%;
    margin: 0 auto;
}
.message-content__bottom_mb {
    width: 100%;
    margin: 0 auto;
}
</style>